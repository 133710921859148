import { cn } from '@vgw/tailwind-merger'
import { Carousel } from '@vgw/multibrand-fe-carousel'
import { LayoutProps } from './layout-factory'
import { LayoutItemCard } from './layout-item-card'

export const LayoutDoubleCard = ({ games, onGameMoreInfo }: LayoutProps) => {
  return games.map((game, index) => {
    if (index % 2 === 0) {
      const nextGame = games[index + 1]

      return (
        <Carousel.Card
          key={game.gameId}
          index={index / 2}
          className={cn([
            'grid',
            'grid-cols-1',
            'grid-rows-2',

            'mr-2',
            'gap-2',

            'h-[228px]',

            'sm:mr-4',
            'sm:gap-4',
            'sm:h-[236px]',

            'lg:mr-6',
            'lg:gap-6',
            'lg:h-[286px]',

            'xl:h-[332px]',

            '2xl:h-[404px]',
          ])}
        >
          <LayoutItemCard
            gameId={game.gameId}
            name={game.name}
            launchUrl={game.launchUrl}
            availability={game.availability}
            imageUrl={game.thumbnails.image1x1}
            variant="square"
            jackpots={game.jackpots}
            onMoreInfo={onGameMoreInfo}
          />
          {nextGame && (
            <LayoutItemCard
              gameId={nextGame.gameId}
              name={nextGame.name}
              launchUrl={nextGame.launchUrl}
              availability={nextGame.availability}
              imageUrl={nextGame.thumbnails.image1x1}
              variant="square"
              jackpots={nextGame.jackpots}
              onMoreInfo={onGameMoreInfo}
            />
          )}
        </Carousel.Card>
      )
    }

    return null
  })
}
