import {
  DrawerDialog,
  DrawerDialogBody,
  DrawerDialogClose,
  DrawerDialogContent,
  DrawerDialogDescription,
  DrawerDialogOverlay,
  DrawerDialogTitle,
} from '@vgw/multibrand-fe-drawer-dialog'
import { CustomIcon } from '@vgw/multibrand-fe-icon'
import {
  PurchaseFiltersContent,
  PurchaseFiltersValues,
} from './purchase-filters-content'
import { Trans } from '@lingui/react/macro'
import { useState } from 'react'
import { Button } from '@vgw/multibrand-fe-button'
import { cn } from '@vgw/tailwind-merger'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'

export type PurchaseFiltersProps = {
  filters: PurchaseFiltersValues | null
  onShowAllResults: (data: PurchaseFiltersValues | null) => void
}

export const PurchaseFilters = ({
  filters,
  onShowAllResults,
}: PurchaseFiltersProps) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false)

  const onShowResults = (data: PurchaseFiltersValues | null) => {
    setDrawerOpen(false)
    onShowAllResults(data)
  }

  const applyFiltersToButton = !!filters

  return (
    <DrawerDialog
      open={isDrawerOpen}
      trigger={
        <Button
          size="sm"
          variant={applyFiltersToButton ? 'primary' : 'ghost'}
          data-testid="purchase-filters-button"
          className={cn({
            'bg-button-surface-brand-primary-active': applyFiltersToButton,
          })}
          onClick={() => setDrawerOpen(true)}
        >
          <CustomIcon
            fill={applyFiltersToButton ? 'fill-white' : 'fill-content-base'}
            name="mixer-horizontal"
            className="-mt-[1px]"
            wrapperClassName="h-3.5 w-3.5"
          />
          <Trans>Filter {applyFiltersToButton ? '(1)' : null}</Trans>
        </Button>
      }
    >
      <DrawerDialogOverlay className="z-20" />
      <DrawerDialogContent className="bg-content-inverse px-6 py-3 max-lg:max-h-[80%] lg:h-full lg:w-[28rem]">
        <DrawerDialogClose onClick={() => setDrawerOpen(false)} />
        <VisuallyHidden.Root>
          <DrawerDialogTitle>
            <Trans>Purchase Filters</Trans>
          </DrawerDialogTitle>
          <DrawerDialogDescription>
            <Trans>
              Filter your purchase history by selecting a specific date range to
              narrow down the results.
            </Trans>
          </DrawerDialogDescription>
        </VisuallyHidden.Root>
        <DrawerDialogBody className="h-full !p-0 lg:h-full lg:overflow-visible">
          <PurchaseFiltersContent
            filters={filters}
            onShowAllResults={onShowResults}
          />
        </DrawerDialogBody>
      </DrawerDialogContent>
    </DrawerDialog>
  )
}
