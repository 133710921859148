import { goldCoinsAssets } from '@/features/gold-coins-assets'

const getGraphicImage = (graphic?: string) => {
  switch (graphic) {
    case 'goldcoins-package-md': {
      return goldCoinsAssets.md
    }
    case 'goldcoins-package-lg': {
      return goldCoinsAssets.lg
    }
    default: {
      return goldCoinsAssets.sm
    }
  }
}

type PeriodicBonusImageProps = {
  graphic: string | undefined
}

export const PeriodicBonusImage = ({ graphic }: PeriodicBonusImageProps) => {
  const { url, urlHidpi } = getGraphicImage(graphic)

  return (
    <img
      data-testid="periodic-bonus-image"
      className="mx-auto my-0.5"
      width={42}
      height={45}
      src={url}
      srcSet={`${url} 42w, ${urlHidpi} 84w`}
      alt=""
    />
  )
}
