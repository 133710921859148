import { Game } from '@/features/game-api/use-gallery-query'
import { Jackpot } from '@vgw/multibrand-fe-game-tile'
import { LayoutSquare } from './layout-square'
import { LayoutPortrait } from './layout-portrait'
import { LayoutRound } from './layout-round'
import { LayoutLandscape } from './layout-landscape'
import { LayoutStackedOne } from './layout-stacked-one'
import { LayoutStackedTwo } from './layout-stacked-two'
import { LayoutStackedThree } from './layout-stacked-three'
import { LayoutStackedFour } from './layout-stacked-four'
import { LayoutTop10Square } from './layout-top10-square'
import { LayoutTop10Portrait } from './layout-top10-portrait'

export interface LayoutProps {
  games: (Game & { jackpots: Jackpot[] })[]
  onGameMoreInfo: (gameId: string) => void
}

const Layouts = {
  square: LayoutSquare,
  portrait: LayoutPortrait,
  landscape: LayoutLandscape,
  round: LayoutRound,
  stackedOne: LayoutStackedOne,
  stackedTwo: LayoutStackedTwo,
  stackedThree: LayoutStackedThree,
  stackedFour: LayoutStackedFour,
  top10Square: LayoutTop10Square,
  top10Portrait: LayoutTop10Portrait,
}

type LayoutVariant = keyof typeof Layouts

export interface LayoutFactoryProps {
  props: LayoutProps
  variant?: LayoutVariant
}

const LayoutFactory = ({ variant = 'square', props }: LayoutFactoryProps) => {
  const Comp = Layouts[variant] || Layouts['square']

  return <Comp {...props} />
}

export { LayoutFactory, type LayoutVariant }
