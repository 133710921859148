import { DrawerClose } from '@vgw/multibrand-fe-drawer'
import { Close } from '@vgw/multibrand-fe-dialog'
import { useIsDesktop } from './use-is-desktop'
import { ComponentProps } from 'react'

type DrawerDialogCloseProps = ComponentProps<typeof DrawerClose> &
  ComponentProps<typeof Close>

export const DrawerDialogClose = ({ ...props }: DrawerDialogCloseProps) => {
  const { isDesktop } = useIsDesktop()

  const Component = isDesktop ? Close : DrawerClose

  return <Component {...props} />
}
