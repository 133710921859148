import goldCoinsPackageSmImageUrl from './goldcoins-package-sm.png'
import goldCoinsPackageSmX2ImageUrl from './goldcoins-package-sm-x2.png'
import goldCoinsPackageMdImageUrl from './goldcoins-package-md.png'
import goldCoinsPackageMdX2ImageUrl from './goldcoins-package-md-x2.png'
import goldCoinsPackageLgImageUrl from './goldcoins-package-lg.png'
import goldCoinsPackageLgX2ImageUrl from './goldcoins-package-lg-x2.png'

export const goldCoinsAssets = {
  sm: {
    url: goldCoinsPackageSmImageUrl,
    urlHidpi: goldCoinsPackageSmX2ImageUrl,
  },
  md: {
    url: goldCoinsPackageMdImageUrl,
    urlHidpi: goldCoinsPackageMdX2ImageUrl,
  },
  lg: {
    url: goldCoinsPackageLgImageUrl,
    urlHidpi: goldCoinsPackageLgX2ImageUrl,
  },
}
