import { createContext, ReactNode, useState, useMemo } from 'react'

interface SelectedCoinTypeContextProviderProps {
  children: ReactNode
}

export type CoinType = 'GC' | 'SC'

interface ContextValue {
  selectedCoinType: CoinType
  setSelectedCoinType: (_coinType: CoinType) => void
}

// eslint-disable-next-line react-refresh/only-export-components
export const SelectedCoinTypeContext = createContext<ContextValue>({
  selectedCoinType: 'GC',
  setSelectedCoinType: () => {},
})

export const SelectedCoinTypeContextProvider = ({
  children,
}: SelectedCoinTypeContextProviderProps) => {
  const [selectedCoinType, setSelectedCoinType] = useState<CoinType>('GC')

  const context = useMemo(
    () => ({
      selectedCoinType: selectedCoinType,
      setSelectedCoinType: setSelectedCoinType,
    }),
    [selectedCoinType, setSelectedCoinType],
  )

  return (
    <SelectedCoinTypeContext.Provider value={context}>
      {children}
    </SelectedCoinTypeContext.Provider>
  )
}
