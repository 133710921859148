import { ErrorBoundary } from 'react-error-boundary'
import { Trans } from '@lingui/react/macro'
import {
  DrawerDialog,
  DrawerDialogOverlay,
  DrawerDialogContent,
  DrawerDialogClose,
  DrawerDialogBody,
} from '@vgw/multibrand-fe-drawer-dialog'
import { useQuestsLaunch } from './use-quests-launch'

export const QuestsDrawer = () => {
  const { isQuestsVisible, onQuestsClose } = useQuestsLaunch()

  if (!isQuestsVisible) {
    return null
  }

  return (
    <DrawerDialog open onOpenChange={onQuestsClose}>
      <DrawerDialogOverlay className="z-20" />
      <DrawerDialogContent
        className="h-max !max-h-none md:min-w-[448px]"
        insetContent={false}
      >
        <DrawerDialogClose size="lg" className="right-0 top-0 m-1" />
        <DrawerDialogBody isContentPadded={false}>
          <ErrorBoundary fallback={<Trans>Something went wrong</Trans>}>
            <QuestsContent />
          </ErrorBoundary>
        </DrawerDialogBody>
      </DrawerDialogContent>
    </DrawerDialog>
  )
}

const QuestsContent = () => {
  return <Trans>Quests Content</Trans>
}
