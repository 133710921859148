import { Link } from 'react-router-dom'
import { GAME_PLAY_PATH } from '@/config/paths'
import { GameTile, GameTileProps, Jackpot } from '@vgw/multibrand-fe-game-tile'
import { cn } from '@vgw/tailwind-merger'
import { MoreInfoButton } from '@vgw/multibrand-fe-more-info-button'
import { useSelectedCoinType } from '@/use-selected-coin-type'
import { Game } from '@/features/game-api/use-gallery-query'
import { useLingui } from '@lingui/react/macro'

type LayoutItemCardProps = Pick<
  Game,
  'gameId' | 'name' | 'launchUrl' | 'availability'
> & {
  imageUrl: string
  jackpots: Jackpot[]
  variant: GameTileProps['variant']
  className?: string
  onMoreInfo: (gameId: string) => void
}

export const LayoutItemCard = ({
  name,
  gameId,
  variant,
  imageUrl,
  className,
  launchUrl,
  availability,
  jackpots,
  onMoreInfo,
}: LayoutItemCardProps) => {
  const selectedCoinType = useSelectedCoinType()
  const { t } = useLingui()

  const isAvailable = availability === 'available'

  return (
    <div className="relative h-full">
      <MoreInfoButton
        onClick={() => onMoreInfo(gameId)}
        className={cn(['absolute', 'right-2', 'top-2', 'z-10'], {
          'pointer-events-none': !isAvailable,
        })}
        aria-label={
          isAvailable
            ? t`Show more information about ${name}`
            : t`${name} is temporarily unavailable`
        }
        aria-disabled={!isAvailable}
        tabIndex={isAvailable ? 0 : -1}
      />
      <Link
        data-testid="game-item-card"
        key={gameId}
        to={`${GAME_PLAY_PATH}/${gameId}`}
        state={{ launchUrl: launchUrl }}
        className={cn(
          [
            'relative',
            'inline-block',
            'h-full',
            'outline-none',
            'rounded-radius-base',

            'before:absolute',
            'before:inset-0',
            'before:rounded-radius-base',

            'focus-visible:before:shadow-[inset_0_0_0_2px]',
            'focus-visible:before:shadow-button-border-focus',
            'focus-visible:before:z-10',
          ],
          {
            'pointer-events-none': !isAvailable,
          },
        )}
        aria-label={
          isAvailable ? t`Play ${name}` : t`${name} is temporarily unavailable`
        }
        aria-disabled={!isAvailable}
        tabIndex={isAvailable ? 0 : -1}
      >
        <GameTile
          name={name}
          imageUrl={imageUrl}
          variant={variant}
          jackpots={jackpots}
          className={className}
          coinType={selectedCoinType}
          isAvailable={isAvailable}
        />
      </Link>
    </div>
  )
}
