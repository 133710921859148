import { cn } from '@vgw/tailwind-merger'
import { Carousel } from '@vgw/multibrand-fe-carousel'
import { LayoutProps } from './layout-factory'
import { LayoutItemCard } from './layout-item-card'
import { Top10Number } from '../top10-number'

export const LayoutTop10Square = ({ games, onGameMoreInfo }: LayoutProps) => {
  return games.map((game, index) => (
    <Carousel.Card
      key={game.gameId}
      index={index}
      className={cn([
        'mr-2',
        'h-[116px]',
        'sm:mr-4',
        'md:h-[174px]',
        'lg:mr-6',
        'xl:h-[218px]',
      ])}
    >
      <Top10Number number={index + 1}>
        <LayoutItemCard
          className="rounded-lg border-2 border-game-card-border-top-10"
          gameId={game.gameId}
          name={game.name}
          launchUrl={game.launchUrl}
          availability={game.availability}
          imageUrl={game.thumbnails.image1x1}
          variant="square"
          jackpots={game.jackpots}
          onMoreInfo={onGameMoreInfo}
        />
      </Top10Number>
    </Carousel.Card>
  ))
}
