import { IconButton, IconButtonProps } from '@vgw/multibrand-fe-icon-button'
import { cn } from '@vgw/tailwind-merger'

type MoreInfoButtonProps = Omit<IconButtonProps, 'icon'>

export const MoreInfoButton = ({
  className,
  ...props
}: MoreInfoButtonProps) => (
  <IconButton
    icon="dots-vertical"
    className={cn(
      'fill-white-alpha-1000 bg-overlay-base hover:bg-black-alpha-500 active:bg-black-alpha-600 focus:button-border-focus',
      className,
    )}
    size="sm"
    {...props}
  />
)
