import { CoinType } from '@vgw/multibrand-fe-coin-type-toggle'
import { IconNames } from '@vgw/multibrand-fe-icon'
import { useLingui } from '@lingui/react/macro'

const language = 'en-US'

// TODO: move to something shared from coin-type-toggle package
const formatGCAmount = (amount: number) => {
  return formatAmount(amount, 0)
}

const formatSCAmount = (amount: number) => {
  const unitAmount = amount / 100
  return formatAmount(unitAmount, 2)
}

const formatAmount = (amount: number, decimals: number = 0) => {
  return new Intl.NumberFormat(language, {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(amount)
}

interface CoinData {
  iconName: IconNames
  iconTitle: string
  coinsFormatter: (coinAmount: number) => string
}

export const useCoinAmount = (coinType: CoinType): CoinData => {
  const { t } = useLingui()

  const isGCCoinType = coinType === 'GC'

  const iconName: IconNames = isGCCoinType
    ? 'coloured:gold-coin'
    : 'coloured:sweeps-coin'

  const coinsFormatter = isGCCoinType ? formatGCAmount : formatSCAmount

  const iconTitle = isGCCoinType ? t`Gold coins` : t`Sweeps coins`

  return {
    iconName,
    coinsFormatter,
    iconTitle,
  }
}
