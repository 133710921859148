import { Chip } from '@vgw/multibrand-fe-chip'
import { RelativeTime } from '@vgw/multibrand-fe-relative-time'

export type TimeLimitProps = {
  validUntil: Date
}

export const TimeLimit = ({ validUntil }: TimeLimitProps) => {
  return (
    <Chip data-testid="time-limit">
      <RelativeTime until={validUntil} />
    </Chip>
  )
}
