import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { cva, VariantProps } from 'class-variance-authority'
import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { msg } from '@lingui/core/macro'
import { cn } from '@vgw/tailwind-merger'
import { useStoreLaunch } from '@/features/store/use-store-launch'
import {
  MobileMenuNavbarItem,
  MobileMenuNavbarItemProps,
} from './mobile-menu-navbar-item'
import { MobileMenuValues, urlToMobileMenuValue } from './types'

const mobileMenuItemsProps: MobileMenuNavbarItemProps[] = [
  {
    label: msg`Home`,
    path: '/',
    value: 'home',
    icon: ['home', 'home-filled'],
  },
  {
    label: msg`Store`,
    value: 'store',
    icon: ['shopping-bag', 'shopping-bag-filled'],
  },
  {
    label: msg`Redeem`,
    path: '/redeem',
    value: 'redeem',
    icon: ['redeem', 'redeem-filled'],
  },
  {
    label: msg`Search`,
    path: '/search',
    value: 'search',
    icon: ['search', 'search-filled'],
  },
  {
    label: msg`Categories`,
    path: '/categories',
    value: 'categories',
    icon: ['play-circle', 'play-circle-filled'],
  },
]

const mobileMenuNavbarVariants = cva(
  [
    'z-20', // TODO: we should create z-index layering for the whole app
    'fixed',
    'flex',
    'justify-center',
    'bg-navigation-surface',
    'p-2',
    'backdrop-blur-sm',
    'sm:px-4',
    'lg:hidden',
    '[&>div]:w-full',
  ],
  {
    variants: {
      variant: {
        primary: ['bottom-0', 'w-full'],
        secondary: ['bottom-0', 'w-full'],
        tertiary: [
          'inset-x-2',
          'bottom-2',
          'inline-flex',
          'rounded-full',
          'shadow-mobile-navbar',
        ],
      },
    },
    defaultVariants: {
      variant: 'primary',
    },
  },
)

export interface MobileMenuNavbarProps
  extends VariantProps<typeof mobileMenuNavbarVariants>,
    NavigationMenu.NavigationMenuProps {
  itemsWithIndicator?: MobileMenuValues[]
}

export const MobileMenuNavbar = ({
  variant,
  className,
  itemsWithIndicator = [],
  ...props
}: MobileMenuNavbarProps) => {
  const location = useLocation()
  const { open: openStoreDialog } = useStoreLaunch()

  const handleMobileNavbarChange = (value: MobileMenuValues) => {
    if (value === 'store') {
      openStoreDialog()
    }
  }

  const activeMobileMenuItem = useMemo(
    () => urlToMobileMenuValue[location.pathname] || null,
    [location.pathname],
  )

  return (
    <NavigationMenu.Root
      data-testid="mobile-menu-navbar"
      className={cn(mobileMenuNavbarVariants({ variant, className }))}
      {...props}
      value={activeMobileMenuItem || ''}
      onValueChange={(value) =>
        handleMobileNavbarChange(value as MobileMenuValues)
      }
    >
      <NavigationMenu.List className="m-0 flex w-full list-none items-center justify-between md:m-auto md:max-w-[90%]">
        {mobileMenuItemsProps.map((mobileMenuItemProp) => (
          <MobileMenuNavbarItem
            key={mobileMenuItemProp.value}
            data-testid={`${mobileMenuItemProp.value}-button`}
            hasNotification={itemsWithIndicator.includes(
              mobileMenuItemProp.value,
            )}
            variant={variant !== 'secondary' ? 'default' : 'withBackground'}
            {...mobileMenuItemProp}
          />
        ))}
      </NavigationMenu.List>
    </NavigationMenu.Root>
  )
}
