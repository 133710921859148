import { Category } from '@/features/category/category'
import { useContext, useMemo, memo } from 'react'
import { SkeletonLobby } from './skeleton-lobby'
import { Jackpots } from '@/features/jackpots/use-jackpots-query'
import { LayoutFactory } from '@/features/category/layout-factory/layout-factory'
import { SelectedCoinTypeContext, CoinType } from '@/selected-coin-type-context'
import { GameCategory } from '@/features/game-api/use-gallery-query'
import { getGamesWithJackpotValue } from '@/features/jackpots/get-games-with-jackpot-value'
import { useGameInfoDrawerLaunch } from '@/features/game-info-drawer/use-game-info-drawer-launch'

interface LobbyProps {
  showLoading: boolean
  categories?: GameCategory[]
  jackpots?: Jackpots
}

export default function Lobby({
  showLoading,
  categories = [],
  jackpots,
}: LobbyProps) {
  const { selectedCoinType } = useContext(SelectedCoinTypeContext)
  const { open: openGameInfo } = useGameInfoDrawerLaunch()

  if (showLoading) return <SkeletonLobby />

  return (
    <section
      className="flex w-full flex-col gap-8 px-2 py-0 sm:px-4 md:px-6 lg:py-4"
      data-testid="lobby"
    >
      {categories.map((category) => (
        <CategoryWrapper
          key={category.name}
          category={category}
          jackpots={jackpots!}
          selectedCoinType={selectedCoinType}
          onGameMoreInfo={openGameInfo}
        />
      ))}
    </section>
  )
}

// this component is needed only to use useMemo for getGamesWithJackpotValue
const CategoryWrapper = memo(
  ({
    category,
    jackpots,
    selectedCoinType,
    onGameMoreInfo,
  }: {
    category: GameCategory
    jackpots: Jackpots
    selectedCoinType: CoinType
    onGameMoreInfo: (gameId: string) => void
  }) => {
    const gamesWithJackpot = useMemo(() => {
      return getGamesWithJackpotValue(
        category.type,
        category.games,
        selectedCoinType,
        jackpots,
      )
    }, [category, jackpots, selectedCoinType])

    return (
      <Category
        name={category.name}
        description={category.description}
        iconUrl={category.iconUrl}
        layout={
          <LayoutFactory
            variant={
              category.type === 'jackpotSpotlight'
                ? 'portrait'
                : category.layout
            }
            props={{
              onGameMoreInfo,
              games: gamesWithJackpot,
            }}
          />
        }
      />
    )
  },
)

CategoryWrapper.displayName = 'CategoryWrapper'
