import { CustomIcon } from '@vgw/multibrand-fe-icon'
import {
  useCountdownDuration,
  useDurationFormat,
} from '@vgw/multibrand-fe-datetime-utils'
import { ReactNode } from 'react'

export type RelativeTimeProps = {
  until: Date
  prefix?: ReactNode
}

export const RelativeTime = ({ until, prefix }: RelativeTimeProps) => {
  const formatDuration = useDurationFormat()
  const duration = useCountdownDuration(until)

  return (
    <div className="flex items-center gap-0.5">
      <CustomIcon
        name={duration.days! > 0 ? 'calendar' : 'clock'}
        size="xs"
        fill="fill-current"
        aria-hidden
      />
      {prefix} <span>{formatDuration(duration)}</span>
    </div>
  )
}
