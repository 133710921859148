import { Chip } from '@vgw/multibrand-fe-chip'
import { cva } from 'class-variance-authority'
import { cn } from '@vgw/tailwind-merger'
import { IconButton } from '@vgw/multibrand-fe-icon-button'
import { useRef, useState } from 'react'
import { Trans, useLingui } from '@lingui/react/macro'
import { msg } from '@lingui/core/macro'
import { i18n } from '@lingui/core'

const chipVariants = cva('text-content text-sm font-light', {
  variants: {
    variant: {
      Pending: 'bg-status-warning-muted',
      Rejected: 'bg-status-error-muted',
    },
  },
})

export type ToastProps = { type: 'success' | 'error'; message?: string }

const statusLabels = {
  Pending: msg`Pending`,
  Rejected: msg`Rejected`,
}

export type PurchaseHistoryCardProps = {
  id: string
  date: Date
  type: string
  amount: string
  status: string
  description: string
  onShowToast: (toast: ToastProps) => void
}

export const PurchaseHistoryCard = ({
  id,
  type,
  date,
  amount,
  status,
  description,
  onShowToast,
}: PurchaseHistoryCardProps) => {
  const { t } = useLingui()

  const [copied, setCopied] = useState(false)
  const timeoutRef = useRef<number | null>()

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(id)
      onShowToast({
        type: 'success',
        message: t`Transaction ID copied to clipboard`,
      })
      setCopied(true)

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = window.setTimeout(() => {
        setCopied(false)
        timeoutRef.current = null
      }, 3000)
    } catch {
      onShowToast({
        type: 'error',
        message: t`Copy to clipboard not supported by this browser`,
      })
    }
  }

  return (
    <div
      className="gap-1 border-b border-border-muted align-top"
      data-testid="purchase-history-card"
    >
      <div className="flex flex-col gap-0.5 py-2">
        <div className="flex justify-between">
          <span className="font-medium">{type}</span> <span>{amount}</span>
        </div>
        <div className="flex items-start justify-between gap-2">
          <span className="text-sm font-normal">{description}</span>
          {(status === 'Pending' || status === 'Rejected') && (
            <Chip className={chipVariants({ variant: status })}>
              {t(statusLabels[status])}
            </Chip>
          )}
        </div>
        <span className="text-sm font-light text-content-muted">
          <Trans>Transaction ID:</Trans>
        </span>
        <span className="flex items-start text-sm font-light text-content-muted">
          <IconButton
            data-testid="transaction-copy-button"
            icon={copied ? 'check-circled' : 'copy'}
            variant="ghost"
            size="sm"
            onClick={!copied ? handleCopy : undefined}
            className={cn('py-1', copied ? 'fill-green-500' : 'fill-[#0058B8]')}
          />
          <span data-testid={`transaction-id-${id}`}>{id}</span>
        </span>
        <span className="text-sm font-light text-content-muted">
          {date.toLocaleString(i18n.locale, {
            timeStyle: 'short',
          })}
        </span>
      </div>
    </div>
  )
}
