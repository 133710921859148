import { cn } from '@vgw/tailwind-merger'
import { Carousel } from '@vgw/multibrand-fe-carousel'
import { LayoutProps } from './layout-factory'
import { LayoutItemCard } from './layout-item-card'

export const LayoutLandscape = ({ games, onGameMoreInfo }: LayoutProps) => {
  return games.map((game, index) => (
    <Carousel.Card
      key={game.gameId}
      index={index}
      className={cn([
        'mr-2',
        'h-[138px]',
        'sm:mr-4',
        'sm:h-[132px]',
        'md:h-[159px]',
        'lg:mr-6',
        'lg:h-[174px]',
        'xl:h-[200px]',
      ])}
    >
      <LayoutItemCard
        gameId={game.gameId}
        name={game.name}
        launchUrl={game.launchUrl}
        availability={game.availability}
        imageUrl={game.thumbnails.image16x9}
        variant="landscape"
        jackpots={game.jackpots}
        onMoreInfo={onGameMoreInfo}
      />
    </Carousel.Card>
  ))
}
