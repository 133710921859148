import { useState, useEffect, useMemo } from 'react'
import { screens } from '@vgw/multibrand-fe-styles'

export const useIsDesktop = () => {
  //eslint-disable-next-line lingui/no-unlocalized-strings
  const query = useMemo(() => matchMedia(`(min-width: ${screens.lg})`), [])
  const { matches: isMobileMatched } = query

  const [isDesktop, setIsDesktop] = useState<boolean>(isMobileMatched)

  useEffect(() => {
    const handleResize = () => {
      const { matches: isDesktop } = query
      setIsDesktop(isDesktop)
    }

    query.addEventListener('change', handleResize)
    return () => {
      query.removeEventListener('change', handleResize)
    }
  }, [query])

  return { isDesktop }
}
