import { Game, GameCategory } from '@/features/game-api/use-gallery-query'
import { Jackpots } from '@/features/jackpots/use-jackpots-query'
import { CoinType } from '@/selected-coin-type-context'
import { sortByIndex } from '@/features/jackpots/sort-by-index'

export const getGamesWithJackpotValue = (
  type: GameCategory['type'],
  games: Game[],
  selectedCoinType: CoinType,
  jackpots?: Jackpots,
) => {
  const gamesWithJackpots = games
    .map(({ gameId, ...game }) => {
      const jackpotsValues = (jackpots?.[gameId]?.jackpots || [])
        .filter((jackpot) => jackpot.coinType === selectedCoinType)
        .map((jackpot) => ({
          name: jackpot.name,
          amount: Number(jackpot.value),
        }))
        .sort((a, b) => b.amount - a.amount)
        .slice(0, type === 'jackpotSpotlight' ? 3 : 1)

      return {
        ...game,
        gameId,
        jackpots: jackpotsValues,
      }
    })
    .sort(sortByIndex)

  if (type === 'jackpotSpotlight') {
    return gamesWithJackpots.filter((game) => game.jackpots.length > 1)
  }

  return gamesWithJackpots
}
