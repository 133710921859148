import { ComponentProps, ElementRef, forwardRef } from 'react'
import { Content as PrimitiveContent } from '@radix-ui/react-dialog'
import { cn } from '@vgw/tailwind-merger'

interface ContentProps extends ComponentProps<typeof PrimitiveContent> {
  isFullScreenOnMobile?: boolean
}

const Content = forwardRef<ElementRef<typeof PrimitiveContent>, ContentProps>(
  ({ className, children, isFullScreenOnMobile = false, ...props }, ref) => (
    <PrimitiveContent
      className={cn(
        [
          ...(isFullScreenOnMobile
            ? [
                'inset-0',
                'md:left-1/2',
                'md:top-1/2',
                'md:-translate-x-1/2',
                'md:-translate-y-1/2',
                'md:bottom-auto',
                'md:right-auto',

                'md:max-h-[90vh]',
                'md:w-full',

                'md:rounded-md',
                'data-[state=open]:animate-dialog-fullscreen-content-show',
                'md:data-[state=open]:animate-dialog-content-show',
                'md:shadow-dialog',
              ]
            : [
                'left-1/2',
                'top-1/2',
                '-translate-x-1/2',
                '-translate-y-1/2',

                'max-h-[90vh]',
                'w-[300px]',

                'rounded-md',
                'data-[state=open]:animate-dialog-content-show',
                'shadow-dialog',
              ]),

          'fixed',
          'z-50',

          'md:w-[400px]',
          'xl:w-[450px]',

          'border',
          'border-border-muted',

          'bg-surface-base',

          'flex',
          'flex-col',

          'focus:outline-none',
        ],
        className,
      )}
      ref={ref}
      aria-modal={true}
      data-testid="dialog-content"
      {...props}
    >
      {children}
    </PrimitiveContent>
  ),
)

Content.displayName = 'DialogContent'

export { Content }
