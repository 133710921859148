import { ErrorBoundary } from 'react-error-boundary'
import { Trans } from '@lingui/react/macro'
import { useGameInfoQuery } from '@/features/game-api/use-game-info-query'
import {
  DrawerDialog,
  DrawerDialogOverlay,
  DrawerDialogContent,
  DrawerDialogClose,
  DrawerDialogBody,
} from '@vgw/multibrand-fe-drawer-dialog'
import { GameTitle } from './game-title'
import { GameConfig } from './game-config/game-config'
import { MoreGames } from './more-games'
import { useToggleGameFavoriteMutation } from './use-toggle-game-favorite-mutation'
import { useGameInfoDrawerLaunch } from './use-game-info-drawer-launch'

export const GameInfoDrawer = () => {
  const { currentGameInfoId, close } = useGameInfoDrawerLaunch()

  if (!currentGameInfoId) {
    return null
  }

  return (
    <DrawerDialog open onOpenChange={close}>
      <DrawerDialogOverlay className="z-20" />
      <DrawerDialogContent
        className="h-max !max-h-none md:min-w-[448px]"
        insetContent={false}
      >
        <DrawerDialogClose size="lg" className="right-0 top-0 m-1" />
        <DrawerDialogBody isContentPadded={false}>
          <ErrorBoundary fallback={<Trans>Something went wrong</Trans>}>
            <GameInfoContent gameId={currentGameInfoId} />
          </ErrorBoundary>
        </DrawerDialogBody>
      </DrawerDialogContent>
    </DrawerDialog>
  )
}

type GameInfoContentProps = {
  gameId: string | null
}

export const GameInfoContent = ({ gameId }: GameInfoContentProps) => {
  const { data, isLoading, error } = useGameInfoQuery(gameId)
  const { toggleFavoriteMutation } = useToggleGameFavoriteMutation(gameId)

  if (isLoading) {
    return null
  }

  if (!data) {
    return null
  }

  if (error) {
    throw error
  }

  const handleToggleFavorite = () =>
    toggleFavoriteMutation(data.gameId, data.isFavorite)

  return (
    <div
      data-testid="game-card-content"
      className="flex max-w-screen-2xl flex-col gap-4 p-4 pb-8 pt-[58px]"
    >
      <GameTitle gameCard={data} handleToggleFavorite={handleToggleFavorite} />
      <p className="text-sm leading-5" data-testid="game-info-description">
        {data.description}
      </p>
      <GameConfig gameConfig={data.config} />
      <hr className="h-[0.0625rem] border-border-muted" />
      <MoreGames games={data.similarGames} />
    </div>
  )
}
