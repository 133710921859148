import { ComponentProps } from 'react'
import { useIsDesktop } from './use-is-desktop'
import { DrawerDescription } from '@vgw/multibrand-fe-drawer'
import { Description as DialogDescription } from '@vgw/multibrand-fe-dialog'

type DrawerDialogDescriptionProps = ComponentProps<typeof DialogDescription> &
  ComponentProps<typeof DialogDescription>

export const DrawerDialogDescription = ({
  children,
  ...props
}: DrawerDialogDescriptionProps) => {
  const { isDesktop } = useIsDesktop()

  const Component = isDesktop ? DialogDescription : DrawerDescription

  return <Component {...props}>{children}</Component>
}
