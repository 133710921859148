import { cn } from '@vgw/tailwind-merger'
import { Carousel } from '@vgw/multibrand-fe-carousel'
import { LayoutProps } from './layout-factory'
import { LayoutItemCard } from './layout-item-card'

export const LayoutPortrait = ({ games, onGameMoreInfo }: LayoutProps) => {
  return games.map((game, index) => (
    <Carousel.Card
      key={game.gameId}
      index={index}
      className={cn([
        'mr-2',
        'h-[173px]',
        'sm:mr-4',
        'md:h-[222px]',
        'lg:mr-6',
        'lg:h-[258px]',
        'xl:h-[315px]',
        '2xl:h-[324px]',
      ])}
    >
      <LayoutItemCard
        gameId={game.gameId}
        name={game.name}
        launchUrl={game.launchUrl}
        availability={game.availability}
        imageUrl={game.thumbnails.image2x3}
        variant="portrait"
        jackpots={game.jackpots}
        onMoreInfo={onGameMoreInfo}
      />
    </Carousel.Card>
  ))
}
