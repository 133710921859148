import { ComponentProps } from 'react'
import { useIsDesktop } from './use-is-desktop'
import { DrawerOverlay } from '@vgw/multibrand-fe-drawer'
import { Overlay as DialogOverlay } from '@vgw/multibrand-fe-dialog'

type DrawerDialogOverlayProps = ComponentProps<typeof DrawerOverlay> &
  ComponentProps<typeof DialogOverlay>

export const DrawerDialogOverlay = ({ ...props }: DrawerDialogOverlayProps) => {
  const { isDesktop } = useIsDesktop()

  const Component = isDesktop ? DialogOverlay : DrawerOverlay

  return <Component {...props} />
}
