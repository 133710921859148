import { HTMLProps, memo, useState } from 'react'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@vgw/tailwind-merger'
import {
  JackpotTicker,
  JackpotTickerProps,
} from '@vgw/multibrand-fe-jackpot-ticker'
import { useJackpotPrioritization } from './hooks/use-jackpot-prioritization'
import { GameTag, GameTagProps } from '@vgw/multibrand-fe-game-tag'
import { Trans, useLingui } from '@lingui/react/macro'

const GameTileVariants = cva(
  ['relative', 'h-full', 'transition', 'duration-300'],
  {
    variants: {
      variant: {
        square: 'aspect-square',
        round: 'aspect-square rounded-full',
        portrait: 'aspect-picture',
        landscape: 'aspect-video',
      },
    },
    defaultVariants: {
      variant: 'square',
    },
  },
)

const GameTagPositionVariants = cva('absolute isolate z-10 -translate-y-2/4', {
  variants: {
    variant: {
      square: 'left-1',
      round: 'left-4',
      portrait: 'left-1',
      landscape: 'left-1',
    },
  },
  defaultVariants: {
    variant: 'square',
  },
})

export type Jackpot = {
  name: NonNullable<JackpotTickerProps['variant']>
  amount: number
}

export type Tag = {
  text: string
  variant: NonNullable<GameTagProps['variant']>
}

export type GameTileProps = HTMLProps<HTMLDivElement> &
  VariantProps<typeof GameTileVariants> & {
    imageUrl: string
    name: string
    jackpots?: Jackpot[]
    className?: string
    tag?: Tag
    coinType: 'SC' | 'GC'
    isAvailable?: boolean
  }

export const GameTile = memo(
  ({
    imageUrl,
    name,
    jackpots = [],
    variant,
    className,
    tag,
    coinType,
    isAvailable = true,
    ...props
  }: GameTileProps) => {
    const { t } = useLingui()
    const [isImageLoaded, setIsImageLoaded] = useState(false)
    const [isImageError, seIsImageError] = useState(false)
    const jackpotsPrioritized = useJackpotPrioritization({ jackpots })

    return (
      <div
        className={cn(
          GameTileVariants({ variant }),
          {
            'animate-pulse duration-[2s]': !isImageLoaded,
            'bg-skeleton': !isImageLoaded || isImageError,
          },
          className,
        )}
        aria-label={name}
        {...props}
      >
        {!!tag && (
          <GameTag
            text={tag.text}
            variant={tag.variant}
            className={cn(GameTagPositionVariants({ variant }))}
          />
        )}
        <div
          className={cn([
            'h-full',
            'max-h-full',
            'w-full',
            'overflow-hidden',
            'relative',
            'isolate',
            variant === 'round'
              ? 'rounded-radius-icon-button'
              : 'rounded-radius-base',
          ])}
        >
          {!isAvailable && (
            <div
              data-testid="temporarily-unavailable"
              className={cn([
                'z-[1]',
                'absolute',
                'h-full',
                'w-full',
                'inset-0',
                'flex',
                'items-center',
                'justify-center',
                'p-2',
                'bg-black/60',
              ])}
              title={t`Temporarily Unavailable`}
            >
              <span
                className={cn([
                  'text-sm',
                  'leading-5',
                  'font-display',
                  'md:text-base',
                  'md:leading-6',
                  'font-bold',
                  'text-white',
                  'text-center',
                  'overflow-hidden',
                  'text-ellipsis',
                  'whitespace-nowrap',
                ])}
              >
                <Trans>
                  Temporarily
                  <br />
                  Unavailable
                </Trans>
              </span>
            </div>
          )}
          <img
            src={imageUrl}
            alt={name}
            className={cn(
              'h-full max-h-full w-full object-cover transition duration-300 hover:scale-105',
              {
                'opacity-0': !isImageLoaded || isImageError,
              },
            )}
            onError={() => {
              seIsImageError(true)
              setIsImageLoaded(true)
            }}
            onLoad={() => setIsImageLoaded(true)}
            data-error={isImageError}
          />
        </div>
        {jackpotsPrioritized.length > 0 && (
          <div
            data-testid="jackpots-container"
            className={cn(
              'absolute',
              'flex',
              'w-full',
              'flex-col',
              'shadow-jackpot',
              'gap-1',
              'p-1',
              'bottom-0',
              'pt-3.5',
              'rounded-b-md',
              'bg-jackpot-ticker-gradient',
            )}
          >
            {jackpotsPrioritized.map((jackpot) => (
              <JackpotTicker
                key={`${jackpot.name}${jackpot.amount}`}
                amount={jackpot.amount}
                variant={jackpot.name}
                coinType={coinType}
              />
            ))}
          </div>
        )}
      </div>
    )
  },
)

GameTile.displayName = 'GameTile'
