import { useSearchParams } from 'react-router-dom'
import { useCallback } from 'react'

const GameInfoDrawerParam = 'game-info'

export const useGameInfoDrawerLaunch = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const close = useCallback(() => {
    setSearchParams(
      (searchParams) => {
        searchParams.delete(GameInfoDrawerParam)
        return searchParams
      },
      { preventScrollReset: true },
    )
    // useSearchParams() change setSearchParams() callbacks on every location change.
    // That causes components relying on this hook, and it's callback to re-render every location change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const open = useCallback(
    (gameId: string) =>
      setSearchParams(
        (searchParams) => {
          searchParams.set(GameInfoDrawerParam, gameId)
          return searchParams
        },
        { preventScrollReset: true },
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const currentGameInfoId = searchParams.get(GameInfoDrawerParam)

  return {
    open,
    close,
    currentGameInfoId,
  }
}
