import { SddForm } from '@/features/sdd/sdd-form/sdd-form'
import { Content } from '@/layout/content'

export const Sdd = () => {
  return (
    <Content mainClassName="bg-surface-tertiary">
      <SddForm />
    </Content>
  )
}
