import { ComponentProps, HTMLAttributes } from 'react'
import { useIsDesktop } from './use-is-desktop'
import { DrawerBody } from '@vgw/multibrand-fe-drawer'
import { Body as DialogBody } from '@vgw/multibrand-fe-dialog'

type DrawerDialogBodyProps = HTMLAttributes<HTMLDivElement> &
  ComponentProps<typeof DrawerBody> &
  ComponentProps<typeof DialogBody>

export const DrawerDialogBody = ({
  children,
  ...props
}: DrawerDialogBodyProps) => {
  const { isDesktop } = useIsDesktop()

  const Component = isDesktop ? DialogBody : DrawerBody

  return <Component {...props}>{children}</Component>
}
