import { DrawerContent } from '@vgw/multibrand-fe-drawer'
import { Content } from '@vgw/multibrand-fe-dialog'
import { ComponentProps } from 'react'
import { useIsDesktop } from './use-is-desktop'

type DrawerDialogContentProps = ComponentProps<typeof DrawerContent> &
  ComponentProps<typeof Content>

export const DrawerDialogContent = ({
  children,
  ...props
}: DrawerDialogContentProps) => {
  const { isDesktop } = useIsDesktop()

  const Component = isDesktop ? Content : DrawerContent

  return (
    <Component position="bottom" {...props}>
      {children}
    </Component>
  )
}
