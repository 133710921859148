import { Duration, add } from 'date-fns'
import { useLingui } from '@lingui/react/macro'

const language = 'en-US'

export const useDurationFormat = () => {
  const { t } = useLingui()

  return (duration: Duration | undefined) => {
    const { days = 0, hours = 0, months = 0, years = 0 } = duration || {}

    if (!duration) {
      return null
    }

    const minutes = (duration.minutes || 0).toString().padStart(2, '0')
    const seconds = (duration.seconds || 0).toString().padStart(2, '0')

    if (days >= 7 || months > 0 || years > 0) {
      return new Intl.DateTimeFormat(language, {
        dateStyle: 'short',
      }).format(add(new Date(), duration))
    }

    if (days >= 1) {
      if (hours > 0) {
        return t`${days}d ${hours}h`
      }

      return t`${days}d ${minutes}m`
    }

    if (hours > 1) {
      return t`${hours}h ${minutes}m`
    }

    return t`${minutes}m ${seconds}s`
  }
}
