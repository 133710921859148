import { ButtonHTMLAttributes, forwardRef } from 'react'
import { CustomIcon, IconNames, IconSizes } from '@vgw/multibrand-fe-icon'
import { cva, VariantProps } from 'class-variance-authority'
import { Slot, Slottable } from '@radix-ui/react-slot'
import { cn } from '@vgw/tailwind-merger'

const iconButtonVariants = cva(
  [
    'group',
    'rounded-radius-icon-button',

    'focus-visible:outline-none',
    'focus-visible:ring-2',
    'focus-visible:ring-button-border-focus',

    'transition-colors',

    'disabled:bg-disabled',
    'disabled:fill-content-disabled',
  ],
  {
    variants: {
      variant: {
        primary: [
          'bg-button-surface-brand-primary',
          'fill-button-icon-brand-primary',
          'hover:bg-button-surface-brand-primary-hover',
          'active:bg-button-surface-brand-primary-active',
        ],
        secondary: [
          'bg-button-surface-brand-secondary',
          'fill-button-icon-brand-secondary',
          'hover:bg-button-surface-brand-secondary-hover',
          'active:bg-button-surface-brand-secondary-active',
        ],
        ghost: [
          'fill-button-icon-ghost',
          'hover:bg-button-surface-ghost-hover',
          'active:bg-button-surface-ghost-active',
        ],
        outline: [
          'border',
          'border-button-icon-outline',
          'fill-button-icon-outline',
          'hover:bg-button-surface-ghost-hover',
          'active:bg-button-surface-ghost-active',
          'disabled:border-transparent',
        ],
      },
      size: {
        sm: 'p-1.5',
        md: 'p-2',
        lg: 'p-2.5',
        xl: 'p-2',
        '2xl': 'p-2.5',
        '3xl': 'p-3',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  },
)

export type IconButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof iconButtonVariants> & {
    icon: IconNames
    hoverIcon?: IconNames
    variant?: 'primary' | 'secondary' | 'outline' | 'ghost'
    size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl'
    iconSize?: IconSizes
    disabled?: boolean
    asChild?: boolean
  }

const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      children,
      className,
      variant,
      size,
      iconSize,
      icon,
      hoverIcon,
      type = 'button',
      asChild = false,
      ...props
    }: IconButtonProps,
    ref,
  ) => {
    const Comp = asChild ? Slot : 'button'

    return (
      <Comp
        className={cn(iconButtonVariants({ variant, size, className }))}
        ref={ref}
        type={type}
        {...props}
      >
        <CustomIcon
          name={icon}
          hoverName={hoverIcon}
          size={iconSize || size}
          fill="disabled:fill-content-disabled"
        />

        <Slottable>{children}</Slottable>
      </Comp>
    )
  },
)
IconButton.displayName = 'IconButton'

export { IconButton }
