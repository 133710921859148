import { useCallback, useMemo, useState } from 'react'
import { I18n } from '@lingui/core'
import { msg } from '@lingui/core/macro'
import { Trans, useLingui } from '@lingui/react/macro'
import {
  Body,
  Close,
  Content,
  Dialog,
  Header,
  Overlay,
  Title,
} from '@vgw/multibrand-fe-dialog'
import { Tab, Tabs } from '@vgw/multibrand-fe-tabs'
import { useStoreLaunch } from '@/features/store/use-store-launch'
import { StoreTab } from '@/features/store/store-tab-enum'
import { PlayForFree } from '@/features/store/play-for-free/play-for-free'
import { BuyGoldCoins } from './buy-gold-coins/buy-gold-coins'
import { Purchase } from '../purchase/purchase'
import { useStoreQuery } from '@/features/store/use-store-query'
import { usePurchaseFlow } from '@/features/store/use-purchase-flow'
import { ToastProvider, Toast, ToastViewport } from '@vgw/multibrand-fe-toast'

const getTabs = (i18n: I18n): Tab[] => [
  {
    label: i18n._(msg`Play for FREE`),
    value: StoreTab.playForFree,
    content: '',
  },
  {
    label: i18n._(msg`Buy Gold Coins`),
    value: StoreTab.buyGoldCoins,
    content: '',
  },
]

type ItemType = 'special-offer-item' | 'regular-store-item'
export type SelectedItem = { type?: ItemType; itemId: string }

export const Store = () => {
  const { i18n } = useLingui()
  const [isPurchaseErrorVisible, setPurchaseErrorVisible] = useState(false)
  const { isOpen, openPurchaseFlow, closePurchaseFlow, selectedItem } =
    usePurchaseFlow()
  const { close, currentTab, changeTab } = useStoreLaunch()

  const {
    data: offers,
    isLoading,
    refetch,
  } = useStoreQuery({ enabled: currentTab === StoreTab.buyGoldCoins })

  const tabs = useMemo(() => getTabs(i18n), [i18n])

  const onExitPurchase = useCallback(() => {
    closePurchaseFlow()
    refetch()
  }, [closePurchaseFlow, refetch])

  const onPurchaseError = useCallback(() => {
    closePurchaseFlow()
    setPurchaseErrorVisible(true)
  }, [closePurchaseFlow])

  return (
    <>
      <Dialog open={currentTab !== undefined && !isOpen} onOpenChange={close}>
        {/* TODO: we should create z-index layering for the whole app */}
        <Overlay className="z-20" />
        <Content
          className="md:h-[90%] md:w-[480px] lg:w-[512px] xl:w-[512px]"
          isFullScreenOnMobile
          data-testid="store"
        >
          <Close data-testid="store-close-button" />
          <Header className="!gap-2 !px-2 !py-3">
            <Title className="text-center font-display text-2xl font-bold leading-9 text-content-base">
              <Trans>Get Coins</Trans>
            </Title>
            <Tabs
              className="md:mx-2 lg:mx-4 xl:mx-6"
              items={tabs}
              defaultValue={currentTab!}
              value={currentTab}
              onValueChange={(value: string) => changeTab(value as StoreTab)}
              hideContent
            />
          </Header>
          <ToastProvider duration={3000}>
            <Toast
              open={isPurchaseErrorVisible}
              close={false}
              onOpenChange={(open) => !open && setPurchaseErrorVisible(false)}
              title={<Trans>Something went wrong. Please try again</Trans>}
              variant="warning"
              className="absolute left-0 right-0 top-12 mx-2 sm:mx-2 md:mx-4 lg:mx-6 xl:mx-8"
              titleClassName="font-normal"
              data-testid="store-error-toast"
            />
            <ToastViewport />
          </ToastProvider>
          <Body className="!sm:px-4 !lg:px-6 !xl:px-6 xs:!px-2 mb-3">
            {currentTab === StoreTab.buyGoldCoins && (
              <BuyGoldCoins
                offers={offers}
                isLoading={isLoading}
                changeTab={changeTab}
                setSelectedItem={openPurchaseFlow}
              />
            )}
            {currentTab === StoreTab.playForFree && (
              <div data-testid="play-for-free-wrapper">
                <PlayForFree />
              </div>
            )}
          </Body>
        </Content>
      </Dialog>
      <Purchase
        item={selectedItem}
        isOpen={isOpen}
        onPurchaseError={onPurchaseError}
        onExitPurchase={onExitPurchase}
      />
    </>
  )
}
