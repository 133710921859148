import { ComponentProps } from 'react'
import {
  Description as PrimitiveDescription,
  DialogDescription,
} from '@radix-ui/react-dialog'
import { cn } from '@vgw/tailwind-merger'

const Description = ({
  className,
  children,
  ...props
}: ComponentProps<typeof DialogDescription>) => (
  <PrimitiveDescription
    className={cn(
      [
        'text-content-base',

        'font-normal',
        'text-base',

        'lg:text-lg',
        'lg:leading-7',
      ],
      className,
    )}
    {...props}
  >
    {children}
  </PrimitiveDescription>
)

Description.displayName = 'DrawerDescription'

export { Description }
