import { useEffect, useMemo, useRef, useState } from 'react'
import { Toast, ToastViewport } from '@vgw/multibrand-fe-toast'
import { PurchaseFilters } from '@/features/purchase-history/purchase-filters/purchase-filters'
import { PurchaseFiltersValues } from '@/features/purchase-history/purchase-filters/purchase-filters-content'
import {
  PurchaseHistoryCardProps,
  ToastProps,
} from '@/features/purchase-history/purchase-history-card'
import { PurchaseHistoryHeader } from '@/features/purchase-history/purchase-history-header'
import { PurchaseHistoryList } from '@/features/purchase-history/purchase-history-list'
import { usePurchaseHistoryInfiniteQuery } from '@/features/purchase-history/use-purchase-history-infinite-query'
import { Trans } from '@lingui/react/macro'
import { CircleLoadingAnimation } from '@vgw/multibrand-fe-circle-loading-animation'
import { Button } from '@vgw/multibrand-fe-button'
import { i18n } from '@lingui/core'

type PurchasesMap = Record<string, PurchaseHistoryCardProps[]>

export const PurchaseHistory = () => {
  const [filters, setFilters] = useState<PurchaseFiltersValues | null>(null)
  const [showToast, setShowToast] = useState(false)
  const [toastDetails, setToastDetails] = useState<ToastProps | undefined>()

  const timerRef = useRef(0)

  useEffect(() => {
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current)
    }
  }, [])

  const handleShowToast = (toast: ToastProps) => {
    setShowToast((value) => {
      clearTimeout(timerRef.current)
      timerRef.current = window.setTimeout(
        () => {
          setShowToast(true)
          setToastDetails(toast)
        },
        value ? 500 : 100,
      )

      return false
    })
  }

  const { to, from } = filters || {}
  const { data, isLoading, isFetchingNextPage, fetchNextPage } =
    usePurchaseHistoryInfiniteQuery({
      query:
        from && to
          ? {
              to: to.toISOString(),
              from: from.toISOString(),
            }
          : undefined,
    })

  const purchases = useMemo(() => {
    const purchasesMap: PurchasesMap =
      data?.pages
        ?.flatMap((page) => page.purchases)
        .reduce((acc: PurchasesMap, purchase) => {
          const item: PurchaseHistoryCardProps = {
            id: purchase.purchaseId,
            amount: purchase.total,
            status: purchase.status,
            date: new Date(purchase.transactedAt),
            type: purchase.method.description,
            description: purchase.item.description,
            onShowToast: handleShowToast,
          }

          const transactedAt = new Date(
            purchase.transactedAt,
          ).toLocaleDateString(i18n.locale, {
            dateStyle: 'medium',
          })

          if (!acc[transactedAt]) {
            acc[transactedAt] = []
          }
          acc[transactedAt].push(item)

          return acc
        }, {}) || {}

    return Object.keys(purchasesMap).map((key) => ({
      title: key,
      items: purchasesMap[key],
    }))
  }, [data])

  const loadedPurchasesCount = useMemo(
    () => purchases.flatMap((item) => item.items).length,
    [purchases],
  )
  const purchasesCount = data?.pages?.[0]?.count

  const isHeaderContentVisible = filters
    ? true
    : purchases.length !== 0 && !isLoading

  return (
    <div className="mx-auto max-w-[480px]">
      <div className="sticky top-0 z-20 bg-white pb-2">
        <PurchaseHistoryHeader />
        <ToastViewport className="fixed left-1/2 top-10 w-[288px] -translate-x-1/2 transform" />
        <Toast
          close={false}
          duration={2500}
          open={showToast}
          variant={toastDetails?.type}
          onOpenChange={setShowToast}
          className="text-status-muted-surface-content data-[state=closed]:animate-fadeOut"
          title=""
          description={toastDetails?.message}
        />
        {isHeaderContentVisible && (
          <div
            className="text-content-tertiary mt-2 flex items-center justify-between px-4 py-1 pr-2 font-display font-medium leading-6"
            data-testid="purchase-history-header-content"
          >
            <Trans>All Purchases</Trans>
            <PurchaseFilters filters={filters} onShowAllResults={setFilters} />
          </div>
        )}
      </div>
      {isLoading ? (
        <div
          data-testid="purchase-history-spinner"
          className="mt-16 flex justify-center"
        >
          <CircleLoadingAnimation />
        </div>
      ) : (
        <>
          {purchases.map((item) => (
            <PurchaseHistoryList key={item.title} {...item} />
          ))}
          {purchasesCount > loadedPurchasesCount && (
            <div className="flex w-full justify-center">
              <Button
                loading={isFetchingNextPage}
                variant="ghost"
                className="border-0"
                onClick={() => fetchNextPage()}
                data-testid="load-more"
              >
                <Trans>Load more</Trans>
              </Button>
            </div>
          )}
          {purchases?.length === 0 && !isLoading && (
            <div
              className="break-words px-8 py-10 text-center font-display text-sm leading-5 text-content-muted"
              data-testid="purchase-history-empty-content"
            >
              <p>
                <Trans>No purchase history</Trans>
              </p>
              {from && to ? (
                <p>
                  <Trans>
                    from {from.toLocaleDateString(i18n.locale)} to{' '}
                    {to.toLocaleDateString(i18n.locale)} period
                  </Trans>
                </p>
              ) : (
                <p>
                  <Trans>in the last 6 months</Trans>
                </p>
              )}
            </div>
          )}
        </>
      )}
    </div>
  )
}
