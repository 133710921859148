import { HTMLAttributes, ReactNode } from 'react'

export interface BodyProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
  isContentPadded?: boolean
}

const Body = ({ className, children, ...props }: BodyProps) => (
  <div className={className} {...props} data-testid="drawer-body">
    {children}
  </div>
)

Body.displayName = 'DrawerBody'

export { Body }
