import { HTMLAttributes } from 'react'
import { cn } from '@vgw/tailwind-merger'

type Top10NumberProps = HTMLAttributes<HTMLDivElement> & {
  number: number
}

export const Top10Number = ({
  children,
  number,
  className,
}: Top10NumberProps) => (
  <div className={cn(['h-full', 'flex', 'items-end'])}>
    <span
      className={cn(
        [
          'z-[-1]',
          'pl-1.5',
          'font-anton',
          'text-[4rem]',
          'md:text-[6rem]',
          'lg:text-[7.5rem]',
          'tracking-[0.375rem]',
          '[-webkit-text-stroke:3px_black]',
          '[-webkit-text-fill-color:transparent]',
          '[mask-image:linear-gradient(to_right,rgba(0,0,0,1),rgba(0,0,0,0))]',
          'mr-[-0.625rem]',
          'md:mr-[-0.75rem]',
        ],
        className,
      )}
    >
      {number}
    </span>
    {children}
  </div>
)
