import { useSearchParams } from 'react-router-dom'
import { useMemo } from 'react'
import { QuestPath } from '@/features/quests/quest-path-enum'

export const useQuestsLaunch = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const onQuestsClose = () => {
    setSearchParams((params) => {
      const newParams = new URLSearchParams(params)
      newParams.delete('quests')
      return newParams
    })
  }

  const paramsWithQuests = useMemo(() => {
    const params = new URLSearchParams(searchParams)
    params.set('quests', QuestPath.activeQuests)
    return params
  }, [searchParams])

  const isQuestsVisible = searchParams.get('quests') === QuestPath.activeQuests

  return { isQuestsVisible, onQuestsClose, paramsWithQuests }
}
