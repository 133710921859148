import { PeriodicBonusesList } from '@/features/periodic-bonus/periodic-bonuses-list/periodic-bonuses-list'
import { Trans } from '@lingui/react/macro'
import { Chip } from '@vgw/multibrand-fe-chip'
import special_offer from '../assets/special_offer.png'
import { PlayForFreeSkeleton } from '@/features/store/play-for-free/play-for-free-skeleton'
import { GracePeriod } from '@/features/periodic-bonus/grace-period/grace-period'
import { usePeriodicBonusClaimMutation } from '@/features/store/play-for-free/hooks/use-periodic-bonus-claim-mutation'
import { useBonusOptionsQuery } from '@/features/store/play-for-free/hooks/use-bonus-options-query'
import { PlayForFreeContent } from '@/features/store/play-for-free/play-for-free-content/play-for-free-content'
import { useBonusDefinitionsQuery } from '@/features/store/play-for-free/hooks/use-bonus-definitions-query'
import {
  PeriodicBonusItem,
  PeriodicBonusType,
} from '@/features/periodic-bonus/periodic-bonus-item/periodic-bonus-item'
import { Instant } from '@/apis/periodic-bonus-service'

export const PlayForFree = () => {
  const {
    definitions,
    error: bonusDefinitionsError,
    isLoading: isBonusDefinitionLoading,
  } = useBonusDefinitionsQuery()
  const {
    data: bonus,
    error: bonusOptionsError,
    isLoading: isBonusOptionsLoading,
  } = useBonusOptionsQuery(definitions)
  const { mutate: claimBonus, isPending: isClaimPending } =
    usePeriodicBonusClaimMutation()

  const onCollectClick = (index: number) => {
    if (!bonus?.bonusId) {
      return
    }
    claimBonus({
      path: { bonusId: bonus?.bonusId },
      body: {
        rewardIndexToClaim: index,
      },
      headers: { Authorization: null },
    })
  }

  if (bonusDefinitionsError || bonusOptionsError) {
    return (
      <p>
        <Trans>An error has occurred</Trans>
      </p>
    )
  }

  if (isBonusOptionsLoading || isBonusDefinitionLoading) {
    return <PlayForFreeSkeleton />
  }

  return (
    <div className="mt-4 flex flex-col gap-6">
      {bonus && (
        <div
          className="relative rounded-lg bg-surface-neutral p-3"
          data-testid="periodic-bonus-items"
        >
          <div className="flex items-center justify-between lg:justify-normal">
            <div className="flex">
              <p className="mr-1.5 font-display text-lg font-bold leading-7 text-content-base">
                {bonus.bonusName}
              </p>
              <img
                width={28}
                height={28}
                src={special_offer}
                className="[filter:drop-shadow(0px_4px_4px_rgba(0,0,0,0.25))] lg:mr-2"
                alt=""
              />
            </div>
            {bonus.streakCount ? (
              <div>
                <Chip>
                  <Trans>{bonus.streakCount} Days Streak</Trans>
                </Chip>
              </div>
            ) : null}
          </div>
          <div className="my-4 flex gap-3">
            <PeriodicBonusesList controlsClassName="absolute right-3 top-3 hidden gap-2 pb-5 lg:flex">
              {bonus.bonuses.map(
                ({ rewardIndex, rewards, type, graphic, variant }) => {
                  const {
                    streakCount,
                    nextClaim,
                    kycStatus: { type: kycStatusType },
                  } = bonus

                  return (
                    <PeriodicBonusItem
                      key={rewardIndex}
                      onCollectClick={() => onCollectClick(rewardIndex)}
                      gc={
                        rewards?.items?.find((coin) => coin.coinType === 'GC')
                          ?.amount
                      }
                      sc={
                        rewards?.items?.find((coin) => coin.coinType === 'SC')
                          ?.amount
                      }
                      index={rewardIndex}
                      countdown={
                        kycStatusType !== 'Required' &&
                        streakCount + 1 === rewardIndex &&
                        nextClaim
                          ? new Date(nextClaim)
                          : undefined
                      }
                      graphic={graphic}
                      type={type as PeriodicBonusType}
                      variant={variant}
                      loading={isClaimPending && type === 'Claimable'}
                      locked={kycStatusType === 'Required'}
                    />
                  )
                },
              )}
            </PeriodicBonusesList>
          </div>

          {bonus.kycStatus?.type !== 'Verified' &&
            bonus.kycStatus?.type !== 'NotRequired' && (
              <GracePeriod
                gracePeriodEnd={
                  (bonus.kycStatus.gracePeriodEnd as Instant)?.value
                }
                status={bonus.kycStatus.type as string}
                onVerifyNow={() => {}}
              />
            )}
        </div>
      )}
      <PlayForFreeContent />
    </div>
  )
}
