import { cn } from '@vgw/tailwind-merger'
import { Trans } from '@lingui/react/macro'

interface SweepsCoinsLabelsProps {
  sweepsCoinsSelected: boolean
  sweepsCoinsAmountFormatted: string
  className?: string
}

export function SweepsCoinsLabel({
  className,
  sweepsCoinsAmountFormatted,
  sweepsCoinsSelected,
}: SweepsCoinsLabelsProps) {
  return (
    <span
      className={cn(
        'flex h-full min-w-full items-center rounded-full border md:min-w-[16.625rem] lg:min-w-[12.25rem]',
        sweepsCoinsSelected
          ? 'border-sweeps-coins-base'
          : 'border-[transparent]',
        className,
      )}
    >
      <span
        data-testid="sweeps-coins-label"
        className={cn(
          'text-sweeps-coins-base',
          !sweepsCoinsSelected && 'opacity-40',
        )}
      >
        <span className="font-semibold">
          <Trans>SC</Trans>
        </span>{' '}
        <span data-testid="sweeps-coins-amount">
          {sweepsCoinsAmountFormatted}
        </span>
      </span>
    </span>
  )
}
