import { Close as PrimitiveClose } from '@radix-ui/react-dialog'
import { useLingui } from '@lingui/react/macro'
import { IconButton } from '@vgw/multibrand-fe-icon-button'
import { ComponentProps } from 'react'
import { cn } from '@vgw/tailwind-merger'

type CloseProps = ComponentProps<typeof PrimitiveClose> & {
  size?: 'sm' | 'md' | 'lg'
}

const Close = ({ className, size = 'sm', ...props }: CloseProps) => {
  const { t } = useLingui()

  return (
    <PrimitiveClose asChild {...props}>
      <IconButton
        data-testid="drawer-close"
        className={cn('pointer-events-auto absolute right-3 top-3', className)}
        size={size}
        icon="cross-1"
        variant="ghost"
        aria-label={t`Close drawer button`}
      />
    </PrimitiveClose>
  )
}

Close.displayName = 'DrawerClose'

export { Close }
