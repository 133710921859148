import { ComponentProps } from 'react'
import { useIsDesktop } from './use-is-desktop'
import { DrawerTitle } from '@vgw/multibrand-fe-drawer'
import { Title } from '@vgw/multibrand-fe-dialog'

type DrawerDialogTitleProps = ComponentProps<typeof DrawerTitle> &
  ComponentProps<typeof Title>

export const DrawerDialogTitle = ({ ...props }: DrawerDialogTitleProps) => {
  const { isDesktop } = useIsDesktop()

  const Component = isDesktop ? Title : DrawerTitle

  return <Component {...props} />
}
