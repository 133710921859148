import Logo from '@/features/header/logo'
import { Link } from 'react-router-dom'
import { Trans } from '@lingui/react/macro'

// TODO: ultimately the brand name should be retrieved from brandConfig
const BRAND_NAME = 'Brand 4'

const GeoBlock = () => {
  return (
    <main className="flex w-full flex-1 flex-col bg-page-small-background bg-contain bg-top bg-no-repeat 2xl:bg-cover">
      <div className="flex flex-1 flex-col items-center justify-center bg-page-gradient text-center">
        <div className="flex grow flex-col">
          <div className="flex flex-col items-center px-3 pt-10">
            <Link to="/">
              <Logo className="h-[70px] w-[39px]" />
            </Link>
          </div>

          <div className="flex grow flex-col items-center justify-center gap-4 px-8 py-10">
            <img className="h-[148px]" src="/geo-block/map-pin.png" alt="" />

            <div className="flex flex-col gap-3 text-center">
              <h1 className="text-2xl font-bold">
                <Trans>Thank you for your interest!</Trans>
              </h1>

              <p className="text-lg font-light">
                <Trans>
                  Unfortunately, {BRAND_NAME} is not available in your region.
                  <br />
                  If you believe you should have access, please visit{' '}
                  <Link className="underline" to="/help-center">
                    Help Center
                  </Link>
                  .
                </Trans>
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
export default GeoBlock
