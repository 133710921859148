import {
  CoinTypeProps,
  CoinTypeToggleWrapper,
} from './coin-type-toggle-wrapper'
import { IconButton } from '@vgw/multibrand-fe-icon-button'
import { useStoreLaunch } from '@/features/store/use-store-launch'
import {
  Drawer,
  DrawerBody,
  DrawerClose,
  DrawerContent,
  DrawerOverlay,
} from '@vgw/multibrand-fe-drawer'
import { UserSidebar } from './user-sidebar/user-sidebar'
import { useQuestsLaunch } from '@/features/quests/use-quests-launch'
import { Link } from 'react-router-dom'
import { CustomIcon } from '@vgw/multibrand-fe-icon'
import { useLingui } from '@lingui/react/macro'

export type UserHeaderProps = {
  logout: () => Promise<void>
  username: string | undefined
  userEmail: string | undefined
  coinTypeInfo: CoinTypeProps
  withCompactStoreIcon?: boolean
}

export const UserHeader = ({
  logout,
  username,
  userEmail,
  coinTypeInfo,
  withCompactStoreIcon,
}: UserHeaderProps) => {
  const { amounts } = coinTypeInfo
  const { t } = useLingui()

  const { open: openStoreDialog } = useStoreLaunch()
  const { paramsWithQuests } = useQuestsLaunch()

  return (
    <>
      <div className="flex w-full gap-3 md:w-auto">
        <div className="hidden gap-3 lg:flex">
          <div className="h-[36px] w-[36px]" />
          <div className="h-[36px] w-[36px]" />
        </div>

        <div className="relative flex w-full">
          <CoinTypeToggleWrapper {...coinTypeInfo} />
          {withCompactStoreIcon && (
            <IconButton
              className="absolute right-[2px] top-[2px] lg:hidden"
              icon="shopping-bag"
              size="sm"
              variant="primary"
              data-testid="mobile-store-button"
              onClick={openStoreDialog}
            />
          )}
        </div>
        <div className="hidden gap-3 lg:flex">
          <IconButton
            icon="shopping-bag"
            size="md"
            variant="primary"
            data-testid="store-button"
            onClick={openStoreDialog}
          />
          <IconButton
            icon="redeem"
            size="md"
            variant="secondary"
            data-testid="redeem-button"
          />
        </div>
      </div>
      <div className="flex items-center gap-2 md:gap-3">
        <Link
          to={{ search: paramsWithQuests.toString() }}
          className="p-1"
          aria-label={t`Open quests dialog`}
          preventScrollReset
        >
          <CustomIcon
            name="crown"
            fill="fill-yellow-600"
            className="h-6 w-6 lg:h-7 lg:w-7"
            wrapperClassName="h-auto w-auto"
          />
        </Link>
        <Drawer
          trigger={
            <div className="cursor-pointer" data-testid="drawer-button">
              <IconButton
                icon="person"
                size="md"
                variant="primary"
                className="hidden lg:block"
                asChild
              >
                <div />
              </IconButton>
              <IconButton
                icon="person"
                size="sm"
                variant="primary"
                className="lg:hidden"
                asChild
              >
                <div />
              </IconButton>
            </div>
          }
        >
          <DrawerOverlay />
          <DrawerContent position="right" className="bg-surface-neutral p-0">
            <DrawerClose />
            <DrawerBody>
              <UserSidebar
                logout={logout}
                username={username}
                userEmail={userEmail}
                coinAmounts={amounts}
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </div>
    </>
  )
}
