import { Drawer } from '@vgw/multibrand-fe-drawer'
import { useIsDesktop } from './use-is-desktop'
import { Dialog } from '@vgw/multibrand-fe-dialog'
import { ComponentProps, useEffect, useState } from 'react'

type DrawerDialogProps = ComponentProps<typeof Drawer> &
  ComponentProps<typeof Dialog>

export const DrawerDialog = ({ children, ...props }: DrawerDialogProps) => {
  const { isDesktop } = useIsDesktop()
  const [isOpened, setIsOpened] = useState(props.open)

  useEffect(() => {
    setIsOpened(props.open)
  }, [props.open])

  const Component = isDesktop ? Dialog : Drawer

  return (
    <Component
      {...props}
      onOpenChange={(open: boolean) => {
        setIsOpened(open)
        props.onOpenChange?.(open)
      }}
      open={isOpened}
    >
      {children}
    </Component>
  )
}
