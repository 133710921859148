import {
  GameCategory,
  useGalleryQuery,
} from '@/features/game-api/use-gallery-query'
import { useLingui } from '@lingui/react/macro'
import { useMemo } from 'react'
import { useFavoriteCategoryQuery } from '@/pages/home/use-favorite-category-query'
import { sortByIndex } from '@/features/jackpots/sort-by-index'
import { LobbyCategoryResponse } from '@/apis/gap-gallery-view'

const useCategoriesQuery = () => {
  const {
    isLoading: isGalleryLoading,
    data: allCategories,
    error: galleryError,
  } = useGalleryQuery()
  const {
    isLoading: isFavoriteCategoryLoading,
    category: favoriteCategory,
    error: favoriteCategoryError,
  } = useFavoriteCategoryQuery()
  const { t } = useLingui()

  const categories: LobbyCategoryResponse[] = useMemo(() => {
    const favorite = favoriteCategory?.category
    const categoriesWithoutFavorite = Array.isArray(allCategories?.categories)
      ? allCategories.categories.filter(
          (category: GameCategory) => category.name !== favorite?.name,
        )
      : []

    const combinedCategories = favorite?.games.length
      ? [favorite, ...categoriesWithoutFavorite]
      : categoriesWithoutFavorite

    return combinedCategories.sort(sortByIndex)
  }, [favoriteCategory, allCategories])

  return {
    categories,
    isLoading: isFavoriteCategoryLoading || isGalleryLoading,
    error:
      galleryError || favoriteCategoryError
        ? t`An error has occurred`
        : undefined,
  }
}

export { useCategoriesQuery }
