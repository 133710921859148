import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { SelectedCoinTypeContext } from '@/selected-coin-type-context'
import { useGameUrlMutation } from '@/pages/game-play/use-game-url-mutation'
import { useTracking } from '@vgw/multibrand-fe-tracking'
import { useGameInfoQuery } from '@/features/game-api/use-game-info-query'
import { Content } from '@/layout/content'
import { Trans } from '@lingui/react/macro'
import { Button } from '@vgw/multibrand-fe-button'

const GamePlay = () => {
  const { gameId } = useParams<{
    gameId: string
  }>()
  const push = useNavigate()
  const { track } = useTracking()
  const { selectedCoinType } = useContext(SelectedCoinTypeContext)

  const { data: game, error: gameError } = useGameInfoQuery(gameId)
  const {
    getGameUrlMutation,
    data: gameUrl,
    error: gameUrlError,
  } = useGameUrlMutation()

  useEffect(() => {
    if (!game) {
      return
    }

    getGameUrlMutation({ launchUrl: game.launchUrl, selectedCoinType })
  }, [selectedCoinType, getGameUrlMutation, game])

  useEffect(() => {
    if (gameUrl?.launchUrl) {
      track('GamePlayPage:GameLaunched', {
        eventData: { gameUrl: gameUrl.launchUrl },
      })
    }
  }, [gameUrl?.launchUrl, track])

  if (gameError || gameUrlError) {
    return (
      <main
        className="flex w-full flex-1 flex-col bg-page-small-background bg-contain bg-top bg-no-repeat 2xl:bg-cover"
        data-testid="game-play-error"
      >
        <div className="flex flex-1 flex-col items-center justify-center gap-3 bg-page-gradient p-8 text-center">
          <img
            src="/game-play/something_went_wrong.png"
            width={122}
            height={122}
            alt=""
            srcSet="/game-play/something_went_wrong.png 122w, /game-play/something_went_wrong@2x.png 244w"
          />
          <p className="font-display text-2xl font-bold">
            <Trans>Something went wrong</Trans>
          </p>
          <p className="text-lg font-light">
            <Trans>Unfortunately, there was an error launching the game.</Trans>
          </p>
          <Button onClick={() => push('/')} size="lg">
            <Trans>Go home</Trans>
          </Button>
        </div>
      </main>
    )
  }

  return (
    <Content>
      {gameUrl?.launchUrl ? (
        <iframe
          title="Game"
          className="h-[calc(100vh-64px)] w-full"
          src={gameUrl.launchUrl}
          data-testid="game-play-iframe"
        />
      ) : null}
    </Content>
  )
}

export default GamePlay
