import { cn } from '@vgw/tailwind-merger'
import { Trans } from '@lingui/react/macro'

interface GoldCoinsLabelProps {
  goldCoinsSelected: boolean
  goldCoinsAmountFormatted: string
  className?: string
}

export function GoldCoinsLabel({
  goldCoinsAmountFormatted,
  goldCoinsSelected,
  className,
}: GoldCoinsLabelProps) {
  return (
    <span
      className={cn(
        'flex h-full min-w-full items-center rounded-full border md:min-w-[16.625rem] lg:min-w-[12.25rem]',
        goldCoinsSelected ? 'border-gold-coins-base' : 'border-[transparent]',
        className,
      )}
    >
      <span
        data-testid="gold-coins-label"
        className={cn(
          'text-gold-coins-base',
          !goldCoinsSelected && 'opacity-40',
        )}
      >
        <span className="font-semibold">
          <Trans>GC</Trans>
        </span>{' '}
        <span data-testid="gold-coins-amount">{goldCoinsAmountFormatted}</span>
      </span>
    </span>
  )
}
