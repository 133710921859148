import {
  Carousel,
  useCarousel,
  WheelGesturesPlugin,
} from '@vgw/multibrand-fe-carousel'
import { Trans, useLingui } from '@lingui/react/macro'
import { useGameInfoDrawerLaunch } from './use-game-info-drawer-launch'
import { Game } from '@/features/game-api/use-gallery-query'
import { cn } from '@vgw/tailwind-merger'

type MoreGameProps = Pick<Game, 'gameId' | 'name' | 'availability'> & {
  imageUrl: string
}

type MoreGamesProps = {
  games: MoreGameProps[]
}

export const MoreGames = ({ games }: MoreGamesProps) => {
  const { t } = useLingui()
  const { open } = useGameInfoDrawerLaunch()
  const [emblaRef] = useCarousel({}, [WheelGesturesPlugin()])

  return (
    <div className="flex flex-col items-start gap-2 self-stretch">
      <div className="text-sm leading-5">
        <Trans>More games like this</Trans>
      </div>
      <Carousel>
        <Carousel.Container ref={emblaRef}>
          <Carousel.CardContainer className="flex items-start gap-2 self-stretch">
            {games.map(({ gameId, availability, name, imageUrl }, index) => {
              const isAvailable = availability === 'available'

              return (
                <Carousel.Card key={gameId} index={index}>
                  <button
                    className={cn(
                      ['relative', 'overflow-hidden', 'rounded-radius-base'],
                      {
                        'pointer-events-none': !isAvailable,
                      },
                    )}
                    {...(isAvailable ? { onClick: () => open(gameId) } : {})}
                    data-testid="game-info-more-games"
                    aria-label={
                      isAvailable
                        ? t`Show more information about ${name}`
                        : t`${name} is temporarily unavailable`
                    }
                    aria-disabled={!isAvailable}
                    tabIndex={isAvailable ? 0 : -1}
                  >
                    {!isAvailable && (
                      <div
                        data-testid="temporarily-unavailable"
                        className={cn([
                          'z-[1]',
                          'absolute',
                          'h-full',
                          'w-full',
                          'inset-0',
                          'flex',
                          'items-center',
                          'justify-center',
                          'bg-black/60',
                        ])}
                        title={t`Temporarily Unavailable`}
                      >
                        <span
                          className={cn([
                            'text-sm',
                            'leading-5',
                            'font-display',
                            'font-bold',
                            'text-white',
                            'text-center',
                            'overflow-hidden',
                            'text-ellipsis',
                            'whitespace-nowrap',
                          ])}
                        >
                          <Trans>
                            Temporarily
                            <br />
                            Unavailable
                          </Trans>
                        </span>
                      </div>
                    )}
                    <img
                      src={imageUrl}
                      className="h-[6.25rem] w-[6.25rem] bg-gray-700"
                      alt={name}
                    />
                  </button>
                </Carousel.Card>
              )
            })}
          </Carousel.CardContainer>
        </Carousel.Container>
      </Carousel>
    </div>
  )
}
