import { type ClassValue, clsx } from 'clsx'
import { extendTailwindMerge } from 'tailwind-merge'
import { borderWidth, borderRadius } from '@vgw/multibrand-fe-styles'

const twMerge = /* __PURE__ */ extendTailwindMerge({
  extend: {
    theme: {
      borderWidth: Object.keys(borderWidth),
      borderRadius: Object.keys(borderRadius),
    },
  },
})

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs))
}
