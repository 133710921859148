import { useEffect, useState } from 'react'
import type { Duration } from 'date-fns'
import { intervalToDuration } from 'date-fns/intervalToDuration'

const getDuration = <T extends Date | undefined>(
  until: T,
): T extends Date ? Duration : Duration | null => {
  if (!until) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return null as any
  }

  if (until.getTime() <= new Date().getTime()) {
    return {}
  }

  return intervalToDuration({
    start: new Date(),
    end: until,
  })
}

export const useCountdownDuration = <T extends Date | undefined>(until: T) => {
  const [duration, setDuration] = useState(getDuration(until))

  useEffect(() => {
    const refreshCounter = () => {
      setDuration(getDuration(until))
    }

    refreshCounter()
    const intervalId = setInterval(refreshCounter, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [setDuration, until])

  return duration
}
